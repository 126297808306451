import React from 'react';
import './App.css';
import TopBar from './topbar';
import Home from './home';
import backImage from './bi.jpg'
import Footer from './footer';

function App() {
  return (
    <div className="app" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundImage: `url(${backImage})`, backgroundSize: 'cover', overflow: 'auto' }}>
        <TopBar  />
        <Home/>
        <Footer/>
        
      </div>
  );
}

export default App;
