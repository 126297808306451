import { Box, Grid, Typography } from '@mui/material';
import icon1 from './Vector.png';
import icon2 from "./excellence 1.png"
import icon3 from "./whoweare.png"
import icon4 from './advertise 1.png'
import icon5 from './handshake 1.png'
import icon6 from './target-audience 1.png'
import icon7 from './WebDev.png'
import icon8 from './mobile-development 1.png'
import icon9 from './homeauto.png'

const Home = () => {
  return (
    <Box sx={{ marginTop: 22,
      '@media (max-width: 800px)': {
       marginTop: 25
      }
     }}>

      <Grid container>
        <Grid item xs={12} md={6} sx={{padding: '25px', margin: '0'}}>
          <Typography variant="h4" align="left" sx={{ marginBottom: 2, color: 'white', fontSize: '30px' }}>
            Welcome to Quadra Edge!
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: "500px",
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(1px)',
            borderRadius: "12px",
            padding: 3
          }}>
            <Typography sx={{ color: "white", marginBottom: 2 }}>
              We are thrilled to announce that Quadra Edge, your new partner in marketing, technology, public relations, and media, is launching soon!
            </Typography>
            <img
              src={icon1}
              alt=""
              style={{ maxWidth: '100px', maxHeight: '69px', marginLeft: 'auto' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ padding: '25px'}}>
          <Typography variant="h5" align="right" sx={{ marginBottom: 2, color: 'white', fontSize: '30px' }}>
            Why Choose Quadra Edge?
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: "500px",
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(1px)',
            borderRadius: "12px",
            padding: 3,
            float: 'right'
          }}>
            <Typography sx={{ color: "white", marginBottom: 2, marginRight: 2 }}>
              At Quadra Edge, we are committed to delivering excellence in everything we do. Our holistic approach ensures that all aspects of your business are integrated and aligned for maximum impact
              We don't just follow trends, we set them, using our expertise to provide innovative solutions that give you a competitive edge.
            </Typography>
            <img
              src={icon2}
              alt=""
              style={{ maxWidth: '120px', maxHeight: '99px', marginLeft: 'auto', paddingTop: '30px' }}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" align="center" sx={{ marginBottom: 2, color: 'white', fontSize: '30px' }}>
            Who We Are
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: "500px",
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(1px)',
            borderRadius: "12px",
            padding: 3,
            margin: '0 auto'
          }}>
            <Typography sx={{ color: "white", marginBottom: 2, }}>
              Quadra Edge is a pioneering company dedicated to transforming the way businesses approach and succeed in the digital age. Our expert team combines extensive experience with cutting-edge strategies to provide comprehensive solutions that drive growth and elevate brand presence.
            </Typography>
            <img
              src={icon3}
              alt=""
              style={{ maxWidth: '100px', maxHeight: '69px', marginLeft: 'auto', paddingTop: '30px' }}
            />
          </Box>
        </Grid>
      </Grid>
      
      <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" align="center" sx={{ marginBottom: 2, color: 'white', fontSize: '30px' }}>
            Our services
          </Typography>
        </Grid>
      </Grid>
      
      <Grid container>
        <Grid item xs={12} md={6} sx={{ padding: '25px'}}>
          <Typography variant="h4" align="left" sx={{ marginBottom: 2, color: 'white', fontSize: '30px' }}>
            Marketing
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: "500px",
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(1px)',
            borderRadius: "12px",
            padding: 3
          }}>
            <Typography sx={{ color: "white", marginBottom: 2 }}>
              Innovative campaigns and strategies tailored to engage your audience and achieve your business objectives            </Typography>
            <img
              src={icon4}
              alt=""
              style={{ maxWidth: '100px', maxHeight: '69px', marginLeft: 'auto' }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={{ padding: '25px'}}>
          <Typography variant="h5" align="right" sx={{ marginBottom: 2, color: 'white', fontSize: '30px' }}>
            Public Relations
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: "500px",
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(1px)',
            borderRadius: "12px",
            padding: 3,
            float: 'right'
          }}>
            <Typography sx={{ color: "white", marginBottom: 2, marginRight: 2 }}>
              We craft compelling stories and build strong connections to boost your brand’s reputation and visibility through strategic communication and media engagement.
            </Typography>
            <img
              src={icon5}
              alt=""
              style={{ maxWidth: '120px', maxHeight: '99px', marginLeft: 'auto' }}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" sx={{ marginTop: 5 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" align="center" sx={{ marginBottom: 2, color: 'white', fontSize: '30px' }}>
            Media
          </Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: "500px",
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(1px)',
            borderRadius: "12px",
            padding: 3,
            margin: '0 auto'
          }}>
            <Typography sx={{ color: "white", marginBottom: 2 }}>
              Creative content creation and distribution to connect with your audience across multiple platforms            </Typography>
            <img
              src={icon6}
              alt=""
              style={{ maxWidth: '120px', maxHeight: '99px', marginLeft: 'auto'}}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid sx={{

      }}>
        <Typography variant="h4" align="left" sx={{ textAlign: 'center', marginBottom: 2, color: 'white', fontSize: '30px', paddingTop: '15px' }}>
          Technology
        </Typography>
      </Grid>
      <Grid container justifyContent="center" sx={{
        marginTop: 5,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(1px)',
        borderRadius: "12px",
        padding: 3
      }}>
        <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Typography sx={{ color: "white", marginBottom: 2, fontWeight: 'bold', fontSize: '17px' }}>Web Development :</Typography>
            <Typography sx={{ color: "white", marginBottom: 2 }}>
              Creating responsive, user-friendly websites that enhance your online presence.
            </Typography>
            <img
              src={icon7}
              alt=""
              style={{ maxWidth: '110px', maxHeight: '89px', paddingTop: '30px' }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Typography sx={{ color: "white", marginBottom: 2, fontWeight: 'bold', fontSize: '17px' }}>iOS and Android App Development:</Typography>
            <Typography sx={{ color: "white", marginBottom: 2 }}>
              Designing and developing mobile apps that provide seamless user experiences.
            </Typography>
            <img
              src={icon8}
              alt=""
              style={{ maxWidth: '100px', maxHeight: '69px', paddingTop: '30px' }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Typography sx={{ color: "white", marginBottom: 2, fontWeight: 'bold', fontSize: '17px' }}> Home Automation IoT Devices :</Typography>
            <Typography sx={{ color: "white", marginBottom: 2 }}>
              Implementing smart home solutions to enhance convenience, security, and efficiency
            </Typography>
            <img
              src={icon9}
              alt=""
              style={{ maxWidth: '100px', maxHeight: '69px', paddingTop: '30px' }}
            />
          </Grid>
        </Grid>
      </Grid>

    </Box>
  );
};

export default Home;