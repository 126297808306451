import React from 'react';
import { Avatar, Box, Button, Grid } from '@mui/material';
import logo from './logoOriginal.svg';
import callIcon from './callIcon.png';
import emailIcon from './emailIcon.png';

const TopBar = () => {
  return (
    <Box
      position="fixed"
      sx={{
        alignContent: 'center',
        height: '150px',
        width: '100vw',
        backgroundColor: 'rgb(255 251 251 / 41%)',
        backdropFilter: 'blur(15px)',
        zIndex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        '@media (max-width: 800px)': {
          display: 'flex',
          flexDirection: 'column',
          height: '200px',
          paddingBottom: '10px'
        }
      }}
    >
      <img
        src={logo}
        alt=""
        style={{ 
          maxWidth: '200px', 
          maxHeight: '80px', 
          margin: '0 auto',
          padding: '10px'
        }}
      />
      <Button 
      startIcon={<Avatar src={callIcon} />}
      sx={{ 
        background: '#2726264e', 
        border: '4px solid #f9f9f9', 
        borderRadius: "25px", 
        fontSize: "14px", 
        color: 'white', 
        width: '250px', 
        position: 'absolute', 
        top: '15%', 
        right: '5%',
        padding: '0',
        fontWeight: 'bold',
        '@media (max-width: 800px)': {
          position: 'unset'        
        }}} href="tel:+919701970364">
        +91-9701970364
      </Button>
      <Button 
      startIcon={<Avatar src={emailIcon} />}
      sx={{ 
        background: '#2726264e', 
        border: '4px solid #f9f9f9', 
        borderRadius: "25px", 
        fontSize: "14px", 
        color: 'white', 
        width: '250px', 
        position: 'absolute', 
        top: '50%', 
        right: '5%',
        padding: '0',
        fontWeight: 'bold',
        '@media (max-width: 800px)': {
          position: 'unset'     
        }}} href="mailto:info@quadraedge.in">
        info@quadraedge.in
      </Button>
    </Box>
  );
};

export default TopBar;
