
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import logo from './logoWhite.svg'

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        color: 'white',
        padding: '20px',
        textAlign: 'center',
        bottom: 0,
        margin: '0 auto',
        marginTop: '20px',
      }}
    >
      <Grid sx={{
        display:"flex",
        flexDirection:'column'
        
      }}>
        <Typography sx={{
          fontSize:'30px',
          
        }}>Stay Tuned!</Typography>
        <Typography
         sx={{
          margin: '0 auto',
          maxWidth:'330px',
          fontSize:'20px'
        }}>Our official launch is just around the corner, and we can't wait to show you what we have in store!</Typography>
      <Grid sx={{
        paddingTop:2,
        paddingLeft:'1px'
      }}>
      <img
          src={logo} 
          alt=""
          style={{ maxWidth: '200px', maxHeight: '80px' }}
        />
      </Grid>
     
      </Grid>
       
      
    </Box>
  );
};

export default Footer;
